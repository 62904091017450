import React, {Fragment} from "react";
import PropTypes from "prop-types";
import generateQrCode from "sepa-payment-qr-code";
import QRCode from "react-qr-code";

import "@styles/components/bank.scss";
import {Trans} from "@language/Translator";

const Bank = ({receiver, price, name, iban, bic, usage, scan, scanType}) => {
    const qr = (iban && receiver && price && usage) ? generateQrCode({
        name: receiver,
        iban: iban,
        amount: price,
        unstructuredReference: usage,
        information: 'EPC code'
    }) : '';

    return (
        <Fragment>
            <div className={'bank-data-row'}>
                <strong><Trans>bank_receiver</Trans>:</strong>
                <div>{receiver}</div>
            </div>
            {name && name.length > 0 && (
            <div className={'bank-data-row'}>
                <strong><Trans>bank_name</Trans>:</strong>
                <div>{name}</div>
            </div>
            )}
            <div className={'bank-data-row'}>
                <strong><Trans>bank_iban</Trans>:</strong>
                <div>{iban}</div>
            </div>
            <div className={'bank-data-row'}>
                <strong><Trans>bank_bic</Trans>:</strong>
                <div>{bic}</div>
            </div>
            {usage && (
                <div className={'bank-data-row'}>
                    <strong><Trans>bank_usage</Trans>:</strong>
                    <div>{usage}</div>
                </div>
            )}
            {scan && qr && (
                <Fragment>
                    <hr />
                    <strong><Trans>bank_qr_scan</Trans></strong>
                    <p><Trans placeholder={[`<strong>${scanType}</strong>`]}>bank_qr_scan_description</Trans></p>
                    <p><strong><Trans placeholder={scanType}>bank_qr_scan_type</Trans></strong></p>
                    <div className={'epc-qr-code-container'}>
                        <QRCode value={qr} size={96}/>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

Bank.propTypes = {
    name: PropTypes.string.isRequired,
    receiver: PropTypes.string.isRequired,
    iban: PropTypes.string.isRequired,
    bic: PropTypes.string.isRequired,
};

export default Bank;