import React, {Fragment, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import RadioGroup from "@components/form/RadioGroup";
import Submit from "@components/form/Submit";
import {rand} from "@helper/helper";
import {updateArrival} from "@helper/api";
import {Trans, useTranslation} from "@language/Translator";


const ArrivalForm = ({arrival, defaultValue, onUpdate}) => {
    const [initial, setInitial] = useState(0);
    const [submitAble, setSubmitAble] = useState(false);
    const [form, setForm] = useState({});

    const t = useTranslation();

    const submit = async () => {
        if(await updateArrival(form)) {
            if(onUpdate) {
                onUpdate(form);
            }
            setSubmitAble(false);
            setInitial(form.arrival);
            return true;
        }

        return false;
    };

    const change = (e) => {
        setSubmitAble(e.target.value.toString() !== initial.toString());
        setForm({...form, [e.target.name]: e.target.value});

        return false;
    };

    useEffect(() => {
        setInitial(defaultValue);
    }, [defaultValue]);

    return (
        <Fragment>
            <Container>
                <Row>
                    <Col md={9} xs={12}>
                        <RadioGroup
                            name={'arrival'}
                            onChange={change}
                            defaultChecked={defaultValue}
                            radios={Object.keys(arrival).map(key => { return {name: t('arrival_time', [arrival[key]]), value: key} })}
                        />
                    </Col>
                    <Col md={3} xs={12}>
                        {submitAble && (
                            <Submit onClick={submit} right={true} key={rand()}><Trans>btn_save</Trans></Submit>
                        )}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ArrivalForm;