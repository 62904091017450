import {useEffect} from "react";

const WebChat = ({reservationNumber, zipCode, apiUrl}) => {
    useEffect( () => {
        if(!reservationNumber || !zipCode || !apiUrl) return;

        const script = document.createElement('script');
        script.src = 'https://github.com/Cognigy/WebchatWidget/releases/latest/download/webchat.js';
        script.async = false;

        const element = document.body.appendChild(script);

        element.onload = () => {
            window.initWebchat(
                "https://endpoint-app.cognigy.ai/9dcf0d2fbde513556ed025f9a017b8140f0f6720af7f31483b7e2de4b2bba214",
                { settings: {
                            getStartedData: {
                                reservationNumber: reservationNumber,
                                zipCode: zipCode,
                                apiUrl: apiUrl
                            }
                    }
                }
            )
        }

        return () => {
            document.body.removeChild(script)
        }
    },[reservationNumber, zipCode, apiUrl])
    return ''
}

export default WebChat;