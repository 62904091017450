import React, {Fragment, useState} from "react";
import {Button, Spinner} from "react-bootstrap";
import "@styles/components/form/submit.scss";
import {rand} from "@helper/helper";

const Submit = ({onClick, children, className, right, ...args}) => {
    const [submitButton] = useState(`submit-button-${rand()}` );

    const click = async () => {
        const btn = document.getElementById(submitButton);
        btn.classList.add('loading');
        btn.setAttribute('disabled', 'disabled');
        if(onClick) {
            await onClick();
        }

        btn.classList.remove('loading');
        btn.removeAttribute('disabled');
    };

    return (
        <Fragment>
            <Button id={submitButton} onClick={click} className={`btn-submit btn btn-primary ${className ? className : ''} ${right ? 'float-right': ''}`} variant="primary" type="button" {...args}>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                {children}
            </Button>
        </Fragment>
    );
};

export default Submit;