import React, {Fragment, useEffect, useState} from "react";
import Accordion from "@components/Accordion";
import AccordionCard from "@components/AccordionCard";
import PropTypes from "prop-types";
import ArrivalForm from "@sections/forms/ArrivalForm";
import {Alert} from "react-bootstrap";
import parse from 'html-react-parser'

import "@styles/sections/arrivalAccordion.scss";
import Link from "@components/Link";
import {toHttpQueryParams} from "@helper/helper";
import {Trans, useTranslation} from "@language/Translator";

const ArrivalAccordion = ({address, arrival, time, date, keyData}) => {
    const [hastWarning, setHasWarning] = useState(false);
    const [collapse, setCollapse] = useState(false);

    const key = 'arrival-accordion';

    const t = useTranslation();

    const update = () => {
        setCollapse(true);
        setHasWarning(true);
    };

    const onCollapse = (state) => {
        setCollapse(state);
    };

    useEffect(() => {
        setHasWarning(time !== -1);
    }, [time]);

    return (
        <Fragment>
            <Accordion defaultActiveKey={collapse ? key : 0} collapse={collapse}>
                <AccordionCard
                    onCollapse={onCollapse}
                    cardId={key}
                    open={collapse}
                    icon={<i className="fa fa-map-signs"/>}
                    label={t('accordion_arrival_headline')}
                    warningLabel={hastWarning ? false : 1}
                    sub={hastWarning ? '' : `Uhrzeit hinterlegen`}>
                    <ul className={'list-group list-group-flush list-group-shaded not-show show-flex'}>
                        <li className={'list-group-item'}>
                            <p>
                                <span className="font-weight-bold"><Trans>accordion_arrival</Trans>
                                    <span className="badge badge-secondary"><Trans>accordion_arrival_time_missing</Trans></span>
                                </span>
                            </p>
                            <ArrivalForm arrival={arrival} defaultValue={time} onUpdate={update}/>

                            {Object.keys(arrival).length > 0 && (
                                <Alert variant={'warning'} className={'alert-arrival-margin-top'}>
                                    <Trans placeholder={[`<strong>${date.format(t('date_format'))}</strong>`, `<strong>${arrival[Object.keys(arrival)[0]]}</strong>`]}>accordion_arrival_available</Trans>
                                </Alert>
                            )}
                        </li>
                        {address.object && (
                        <li className={'list-group-item'}>
                            <p>
                                <span className="font-weight-bold"><Trans>accordion_arrival_address</Trans></span>
                            </p>

                            <p>
                                {address.object.name}<br />
                                {address.object.street}<br />
                                {address.object.zip} {address.object.city}
                            </p>
                            <p>
                                <Link button url={`https://maps.google.de/?${toHttpQueryParams({
                                        saddr: [address.customer.street, address.customer.zip, address.customer.city],
                                        daddr:[address.object.street, address.object.zip, address.object.city]
                                    }
                                )}&z=15&om=1`}><Trans>accordion_arrival_address_route</Trans></Link>
                            </p>
                        </li>
                        )}
                        <li className={'list-group-item'}>
                            <p>
                                <span className="font-weight-bold"><Trans>accordion_arrival_key</Trans></span>
                            </p>
                            {parse(keyData)}
                        </li>
                    </ul>
                </AccordionCard>
            </Accordion>
        </Fragment>
    );
};

ArrivalAccordion.propTypes = {
    address: PropTypes.object.isRequired,
    arrival: PropTypes.object.isRequired,
    date: PropTypes.object.isRequired
};


export default ArrivalAccordion;