import { Alert, Button, Container } from "react-bootstrap";
import React from "react";
import { Trans, useTranslation } from "@language/Translator";
import Bank from "./Bank";
import moment from "moment";
import PropTypes from "prop-types";
import { FaRegCreditCard, FaExchangeAlt, FaHome } from "react-icons/fa";

const STATUS_COLORS = {
  succeeded: "success",
  open: "primary",
  toExpire: "warning",
  expired: "danger",
};

const STATUS_VALUES = {
  open: "OPEN",
  succeeded: "SUCCEEDED",
};

const METHOD_ICON = {
  creditCard: <FaRegCreditCard />,
  transfer: <FaExchangeAlt />,
  onLocation: <FaHome />,
};

const OptionalStrong = ({ isStrong, children }) => {
  if (isStrong) {
    return <strong>{children}</strong>;
  }
  return children;
};

const AsyncPaymentContainer = ({
  statusColor,
  paymentMethod,
  placeholders,
  paymentUrl,
}) => {
  const t = useTranslation();

  const isPaid = statusColor === STATUS_COLORS.succeeded;
  const title = isPaid ? "ds_payment" : "accordion_payment_pay_now_title";

  return (
    <Container className="optional">
      <Alert variant={statusColor}>
        <h5>
          <p>
            {METHOD_ICON[paymentMethod]} {t(title)}
          </p>
        </h5>
        {isPaid ? (
          <Trans placeholder={[placeholders[1]]}>
            payment_ds_async_succeeded
          </Trans>
        ) : (
          <>
            <p>
              <Trans>payment_ds_async</Trans>
            </p>
            <p>
              <OptionalStrong isStrong={statusColor === STATUS_COLORS.expired}>
                <Trans placeholder={placeholders}>
                  accordion_payment_deposit_deadline_with_days
                </Trans>
              </OptionalStrong>
            </p>
            <div className="d-flex flex-row-reverse">
              <Button href={paymentUrl} disabled={!paymentUrl}>
                Jetzt Bezahlen
              </Button>
            </div>
          </>
        )}
      </Alert>
    </Container>
  );
};

AsyncPaymentContainer.propTypes = {
  paymentMethod: PropTypes.string,
  statusColor: PropTypes.string,
  placeholders: PropTypes.array,
  depositDeadline: PropTypes.string,
  paymentUrl: PropTypes.string,
};

const Payment = ({ asyncDsPayment, payment, label, bookingNumber }) => {
  const t = useTranslation();
  const dateFormat = t("date_format");

  const daysThreshold = 14;

  const daysUntilDeadline = Math.ceil(
    payment.deadline.diff(moment(), "days", true)
  );
  const isOverdue = daysUntilDeadline <= 0;

  const statusColor =
    payment.paymentStatus === STATUS_VALUES.succeeded
      ? STATUS_COLORS.succeeded
      : isOverdue
      ? STATUS_COLORS.expired
      : daysUntilDeadline <= daysThreshold
      ? STATUS_COLORS.toExpire
      : STATUS_COLORS.open;

  const placeholders = [
    t(label),
    payment.price.toFixed(2),
    isOverdue ? t("payment_prefix_past") : t("payment_prefix_future"),
    Math.abs(daysUntilDeadline),
    payment.deadline.format(t("date_format")),
    isOverdue ? t("payment_overdue") : "",
  ];

  if (asyncDsPayment) {
    return (
      <AsyncPaymentContainer
        statusColor={statusColor}
        paymentMethod={payment.type}
        placeholders={placeholders}
        paymentUrl={payment.paymentUrl}
      />
    );
  }

  if (payment.type === "transfer") {
    return (
      <>
        <p>
          <Trans
            placeholder={[
              t(label),
              payment.price.toFixed(2),
              payment.deadline.format(dateFormat),
            ]}
          >
            accordion_payment_pay
          </Trans>
        </p>
        <Bank
          bic={payment.bank.bic}
          receiver={payment.bank.receiver}
          iban={payment.bank.iban}
          name={payment.bank.name}
          usage={t("accordion_payment_pay_usage", [t(label), bookingNumber])}
          scan={true}
          price={payment.price}
          scanType={t(label)}
        />
      </>
    );
  }

  if (payment.type === "onlocation") {
    return <Trans>payment_on_location</Trans>;
  }
  return <></>;
};

Payment.propTypes = {
  asyncDsPayment: PropTypes.bool,
  payment: PropTypes.any,
  bookingNumber: PropTypes.string,
  label: PropTypes.string,
};

export default Payment;
