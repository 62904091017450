import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import Accordion from "@components/Accordion";
import AccordionCard from "@components/AccordionCard";
import {Trans, useTranslation} from "@language/Translator";

const DepartureAccordion = ({date, time}) => {
    const [collapse, setCollapse] = useState(false);

    const key = 'departure-accordion';

    const t = useTranslation();

    const onCollapse = (state) => {
        setCollapse(state);
    };

    return (
        <Fragment>
            <Accordion defaultActiveKey={collapse ? key : 0} collapse={collapse}>
                <AccordionCard
                    onCollapse={onCollapse}
                    cardId={key}
                    open={collapse}
                    icon={<i className="fa fa-briefcase" />}
                    label={t('accordion_departure_headline')}>
                    <ul className={'list-group list-group-flush not-show list-group-shaded show-flex'}>
                        <li className={'list-group-item'}>
                            <Trans placeholder={[`${time}:00`, date.format(t('date_format'))]} >accordion_departure_time</Trans>
                        </li>
                    </ul>
                </AccordionCard>
            </Accordion>
        </Fragment>
    );
};

DepartureAccordion.propTypes = {
    date: PropTypes.object.isRequired,
    time: PropTypes.number.isRequired,
};

export default DepartureAccordion;