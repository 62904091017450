import React, {Fragment} from 'react';
import {Button as BootstrapButton} from "react-bootstrap";
import '@styles/components/link.scss';

const Link = ({button, children, url, className}) => {

    return (
        <Fragment>
            <BootstrapButton className={`iw-ds-button ${button ? 'btn-primary' : ''} ${className ? className : ''}`} variant="link" target={'_blank'} href={url}>{children}</BootstrapButton>
        </Fragment>
    );
};

export default Link;