import {Trans} from "../language/Translator";
import React from "react";
import {ListGroupItem} from "react-bootstrap";
import moment from "moment";

/**
 * Returns the index and placeholder of translation considering the hours
 *
 * default: list_item_cancel_hours_default
 * with date: list_item_cancel_hours_date, 01.01.2022 (arrivalDate-cancelDays)
 * with time: list_item_cancel_hours_time, 18 (24-hours)
 *
 * @param hours
 * @param costEndDate
 * @param percent
 * @returns {{index: string, placeholder: null|string|number}}
 */
const getTransOfCancelHours = (hours, costStartDate, costEndDate, percent) => {
    let formatted = {};
    if (hours === "-1" && percent == null) {
        formatted = {index: "list_item_cancel_hours_default", placeholder: costEndDate};
    }
    if (hours > 24 || (hours === "-1" && percent != null)) {
        formatted = {index: "list_item_cancel_hours_date", from: costStartDate, to: costEndDate};
    }

    if (hours <= 24 && hours > -1) {
        let time = 24-hours;
        formatted = {index: "list_item_cancel_hours_time", time: time};
    }

    return formatted;
}

/**
 * Creates the cancel item to display in frontend
 *
 * @param string start     Start date of specific cancel period
 * @param string end       End date of specific cancel period
 * @param number min       Minimum cancel price
 * @param object cost      Object of cost policy
 * @param string costIndex Hours (defined the index) of cost policy
 * @returns {JSX.Element}
 */
export const makeCancelItem = (start, end, min, cost, costIndex) => {
    let cancelPeriod, cancelMinPrice = "";
    let cancelHours = getTransOfCancelHours(costIndex, cost.startDate, cost.endDate, cost.percent);

    if (start !== undefined && end !== undefined) {
        cancelPeriod = <Trans
            placeholder={[start, end]}>list_item_cancel_period</Trans>
    }

    let active = false;
    const now = moment();
    let placeholder = [cost.percent];
    if(cancelHours.placeholder) {
        placeholder.push(cancelHours.placeholder);
        active = now.isSameOrBefore(moment(cancelHours.placeholder, 'DD.MM.YYYY'));
    } else if(cancelHours.from && cancelHours.to) {
        placeholder.push(cancelHours.from);
        placeholder.push(cancelHours.to);
        active = now.isSameOrAfter(moment(cancelHours.from, 'DD.MM.YYYY')) && now.isSameOrBefore(moment(cancelHours.to, 'DD.MM.YYYY'));
    } else if(cancelHours.time) {
        placeholder.push(cancelHours.time);
        active = now.isSameOrAfter(moment(`${cost.startDate} ${cancelHours.time < 10 ? `0${cancelHours.time}`: cancelHours.time}:00`, 'DD.MM.YYYY HH:mm'));
    }


    return (
    <ListGroupItem key={costIndex} className={'blue-on'}>
        <div className={`cancellation-bubble ${active ? 'cancelActive' : ''}`.trim()}>
            {cancelPeriod}
            {cost.percent == null && (<Trans placeholder={[cancelHours.placeholder]}>{cancelHours.index}</Trans>)}
            {cost.percent != null && (<Trans placeholder={placeholder}>{cancelHours.index}</Trans>)}
            {cancelMinPrice}
            <Trans
                placeholder={cost.cancelPrice}>
                list_item_cancel_policy_cancelPrice
            </Trans>
        </div>
    </ListGroupItem>
    );
}