import React, {Fragment, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CheckboxButton from "@components/form/CheckboxButton";
import Button from "@components/Button";

import "@styles/components/cardItem.scss";

const CardItem = ({booked, enabled, name, label, price, onClick}) => {
    /**
     * STATES
     */
    const [checked, setChecked] = useState(enabled);

    const click = (e) => {
        if(onClick) {
            onClick(e);
        }

        setChecked(e.target.checked);
    };

    return (
        <Fragment>
            {label && (
            <div className={'card'}>
                    <Container className={'card-body'}>
                        <Row>
                            <Col lg={8} xs={12}>
                                <h4 className={'inline-block'}>
                                    <i className="fa fa-shopping-cart"/>
                                    {label}
                                </h4>
                            </Col>
                            <Col lg={4} xs={12}>
                                {!booked && (
                                <CheckboxButton type={'checkbox'} className={'float-right full-width'} value={price} name={name}
                                                defaultChecked={checked} onClick={click}>+ {price.toFixed(2)} €</CheckboxButton>
                                )}
                                {booked && (
                                <Button right={true} disabled={true} className={'option-button d-block d-sm-block btn-outline-primary'}>✓ {price.toFixed(2)} &euro;</Button>
                                )}
                            </Col>
                        </Row>
                    </Container>
            </div>
            )}
        </Fragment>
    );
};

export default CardItem;