import React, {Fragment, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import CardItem from "@components/CardItem";
import PropTypes from "prop-types";
import {rand, sum} from "@helper/helper";

import "@styles/sections/bookAdditionalServices.scss";
import Submit from "@components/form/Submit";
import {bookAdditionalServices} from "@helper/api";
import {Trans} from "@language/Translator";

const BookAdditionalServices = ({exclusives}) => {
    const [state, setState] = useState({});
    const [collection, setCollection] = useState([]);

    const onChange = (e) => {
        let data = {...state};

        if(state[e.target.name]) {
            delete data[e.target.name];
        } else {
            data[e.target.name] = e.target.value;
        }
        setState(data);
    };

    const submit = async () => {

        if(await bookAdditionalServices(state)) {
            let update = [...collection];

            for(let cartItemName in state) {
                const index = update.findIndex((item) => { return item.name ===  cartItemName});
                update[index].booked = true;
            }

            setCollection(update);
            setState({});
            return true;
        }

        return false;
    };

    useEffect(() => {
        setCollection(exclusives);
    }, [exclusives]);

    return (
        <Fragment>
            <Container className={'optional'}>
                <Row>
                        {collection.map((item) => (
                            <Col xs={12} lg={6} key={rand()}>
                                <CardItem name={item.name} label={item.label} price={item.price} enabled={state[item.name] !== undefined} booked={item.booked} onClick={onChange}/>
                            </Col>
                        ))}
                        <Col>
                            <Container>
                                <Row className={`row jumbotron payoption ${Object.values(state).length > 0 ? 'payoptionon' : ''}`}>
                                    <Col xs={8}>
                                        <div className={'lead'}>
                                            <h4><span className="double_underline"><Trans placeholder={[sum(Object.values((state))).toFixed(2)]}>booking_additional_service_sum</Trans></span></h4>
                                            <Trans>booking_additional_service_pay_info</Trans>
                                        </div>
                                    </Col>
                                    <Col xs={4}>
                                        <Submit onClick={submit} right={true} key={rand()}><Trans>btn_book</Trans></Submit>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

BookAdditionalServices.propTypes = {
    exclusives: PropTypes.array.isRequired,
};

export default BookAdditionalServices;