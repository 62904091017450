import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Container} from "react-bootstrap";

import "@styles/sections/ergoTravelInsurance.scss";

const ErgoTravelInsurance = ({ergoLink}) => {
    return (
        <Fragment>
            <Container className={'optional'}>
                <div className="alert alert-primary" role="alert">
                    <h4><i className="fa fa-shield" /> &nbsp;Unser Tipp: Gut
                        geschützt in den Urlaub</h4>

                    <p className="spacer-top-1 margin-bottom-0">
                        Es kann immer etwas Unvorhergesehenes passieren. Damit
                        Sie bei Stornierung und Abbruch Ihrer Reise finanziell
                        abgesichert sind, empfehlen wir Ihnen den Abschluss
                        einer Reiserücktritts-Versicherung ohne
                        Selbstbeteiligung bei unserem Partner ERGO
                        Reiseversicherung.
                    </p>

                    <p className="spacer-top-1">
                        <a href={ergoLink} target="_blank" rel="noreferrer"
                           className="btn btn-primary float-right">Hier online
                            abschließen</a>
                    </p>
                    <div className="clear" />
                </div>
            </Container>
        </Fragment>
    );
}

ErgoTravelInsurance.propTypes = {
    ergoLink: PropTypes.string.isRequired
}


export default ErgoTravelInsurance;