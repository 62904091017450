import React, {useEffect, useState} from 'react';
import {Spinner} from "react-bootstrap";

import "@styles/components/pageContentLoader.scss";
import Modal from "@components/Modal";
import {Trans} from "@language/Translator";

const PageContentLoader = ({show}) => {
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        setDisplay(show);
    }, [show]);

    return (
        <Modal
            show={display}
            closeAble={false}
            title={'Lade Buchung ...'}
        >
            <span className={'page-content-loader-label'}><Trans>page_loader_label</Trans></span>
            <Spinner variant="primary" animation="border" className={'page-loader-spinner'}/>
        </Modal>
    );
};

export default PageContentLoader;