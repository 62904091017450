import Dashboard from "./pages/Dashboard";
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ToastContainer} from "react-toastify";
import React, {Fragment} from "react";

import "bootstrap/dist/css/bootstrap.min.css"
import "@styles/lib/fa/font-awesome.min.css";
import "@styles/app.scss";
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <Fragment>
            <ToastContainer/>
            <Router>
                <Switch>
                    <Route path='/:bookingNumber/:token' component={Dashboard}/>
                    <Route path='/:bookingNumber' component={Dashboard}/>
                    <Route path='/' component={Dashboard}/>
                </Switch>
            </Router>
        </Fragment>
    );
}

export default App;