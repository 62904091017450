import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import '@styles/components/shortBooking.scss';
import {Trans} from "@language/Translator";

const ShortBooking = ({bookingNumber, image, adults, children, childAges, object, room, arrival, departure}) => {

    const ages = Object.values({...childAges});
    const lastAge = ages.length > 1 ? ages.pop() : false;

    let styles = {};

    if(image) {
        styles = {backgroundImage: `url("${image}")`};
    }

    return (
        <Fragment>
            <li key={bookingNumber} className={'list-group-item'}>
                <div className={'float-left card-status-image'}>
                    <div className={'status-img'} style={styles}/>
                </div>
                <div>
                    <strong><Trans>booking_number</Trans> {bookingNumber}</strong>
                    <div>
                        <Trans>object_label</Trans>: {object}
                        <span className={'list-item__separator'} />
                        {room}
                        <span className={'list-item__separator'} />
                        {arrival && arrival.date.format('DD.MM.YYYY')}
                        &nbsp;bis&nbsp;
                        {departure && departure.date.format('DD.MM.YYYY')}
                    </div>
                    <div>
                        <Trans placeholder={[adults]}>short_booking_adults_count</Trans>
                        {children > 0 && (
                            <Fragment>
                                <span className={'list-item__separator'} />
                                <Trans placeholder={[children, ages.join(', '), lastAge]}>short_booking_children_count</Trans>
                            </Fragment>
                        )}
                    </div>
                </div>
            </li>
        </Fragment>
    );
};

ShortBooking.propTypes = {
    bookingNumber: PropTypes.string.isRequired,
    adults: PropTypes.number.isRequired,
    room: PropTypes.string.isRequired,
    object: PropTypes.string.isRequired,
    arrival: PropTypes.object.isRequired,
    departure: PropTypes.object.isRequired,
};


export default ShortBooking;