import React, {Fragment} from 'react';
import {Accordion as BootstrapAccordion} from "react-bootstrap";

import '@styles/components/accordion.scss';

const Accordion = ({children, collapse, defaultActiveKey}) => {
    return (
        <Fragment>
            <BootstrapAccordion className={`card ${collapse ? 'open blue-on' : ''}`} defaultActiveKey={defaultActiveKey}>
                {children}
            </BootstrapAccordion>
        </Fragment>
    );
};

export default Accordion;