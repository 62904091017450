import React, {Fragment, useEffect, useState} from "react";
import {ButtonGroup, ToggleButton} from "react-bootstrap";

import "@styles/components/form/radio.scss";
import PropTypes from "prop-types";

const RadioGroup = ({name, onChange, radios, defaultChecked}) => {
    const [radioValue, setRadioValue] = useState(0);

    const change = (e) =>  {
        if(onChange) {
            onChange(e);
        }

        setRadioValue(e.currentTarget.value);
    };

    useEffect(() => {
        setRadioValue(defaultChecked);
    }, [defaultChecked]);

    return (
        <Fragment>
            <ButtonGroup toggle className={`single-button-style`}>
                {radios.map((radio, idx) => (
                    <ToggleButton
                        key={idx}
                        className={`btn option-button form-check-label`}
                        type="radio"
                        variant="outline-primary"
                        name={name}
                        value={radio.value}
                        checked={radioValue && radioValue.toString() === radio.value.toString()}
                        onChange={change}
                    >
                        {radio.name}
                    </ToggleButton>
                ))}
            </ButtonGroup>
        </Fragment>
    );
};

RadioGroup.propTypes = {
    name: PropTypes.string.isRequired,
};

export default RadioGroup;