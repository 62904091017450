import React, {Fragment} from 'react';
import '@styles/components/frame.scss';
import PropTypes from "prop-types";

const Frame = ({title, src, className, ...args}) => {

    return (
        <Fragment>
            <iframe title={title} src={src} className={`external-data-frame ${className ? className : ''}`} {...args}/>
        </Fragment>
    );
};

Frame.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
};

export default Frame;

