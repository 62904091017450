import React, {Fragment, useState} from 'react';
import {Button, Modal, Form} from "react-bootstrap";

import "@styles/components/modal.scss";
import "@styles/sections/contactLandlord.scss";
import RadioGroup from "@components/form/RadioGroup";
import {sendMailToLandlord} from "@helper/api";
import Submit from "@components/form/Submit";
import {Trans, useTranslation} from "@language/Translator";

const ContactLandlord = ({icon, label, title, contact}) => {

    const [show, setShow] = useState(false);
    const [form, setForm] = useState({});

    const t = useTranslation();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const submit = async () => {
        if (await sendMailToLandlord(form)) {
            setShow(false);
            setForm({});
        }
    };

    return (
        <Fragment>
            <Button variant="outline" onClick={handleShow} className={'modal__button'}>
                {icon}
                <div className={'modal__button__label'}>{label}</div>
            </Button>

            <Modal show={show} onHide={handleClose} centered size={'lg'}>
                <Modal.Header closeButton>
                    <div className={'modal-title h5'}>{title}</div>
                </Modal.Header>
                <Modal.Body>
                    {contact.available && contact.available.phone && (
                        <Trans placeholder={[`<a href="tel:${contact.phone}" class="phone-landlord">${contact.phone}</a>`]}>
                            modal_contact_landlord_phone
                        </Trans>)}
                    {contact.available && contact.available.email && contact.available.phone && (<hr/>)}
                    {contact.available && contact.available.email && (<Fragment>
                        <p><Trans>modal_contact_landlord_type</Trans>:</p>
                        <RadioGroup
                            name={'type'}
                            onChange={onChange}
                            defaultChecked={form.type}
                            radios={[
                                {name: t('contact_landlord_general_label'), value: 'general'},
                                {name: t('contact_landlord_recall_label'), value: 'recall'},
                                {name: t('contact_landlord_arrival_label'), value: 'arrival'},
                                {name: t('contact_landlord_complaint_label'), value: 'complaint'}
                            ]}
                        />
                        <Form.Group className={'mt-4'}>
                            <Form.Control as="textarea" rows={3} placeholder={t('modal_contact_landlord_placeholder')} name={'message'}
                                          value={form.message} onChange={onChange}/>
                        </Form.Group>
                    </Fragment>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        <Trans>modal_close</Trans>
                    </Button>
                    <Submit variant="primary" disabled={form.message && form.message.length > 0 && form.type ? '' : 'disabled'}
                            onClick={submit}>
                        <Trans>btn_send</Trans>
                    </Submit>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default ContactLandlord;