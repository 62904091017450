import React, {Fragment} from "react";
import {Form} from "react-bootstrap";
import PropTypes from "prop-types";

import "@styles/components/form/checkbox.scss";
import {rand} from "@helper/helper";

const Checkbox = ({label, value, name, ...args}) => {
    return (
        <Fragment>
            <Form.Group>
                <Form.Check type="checkbox" label={label} name={name} {...args} id={`checkbox-${rand()}`}/>
            </Form.Group>
        </Fragment>
    );
};

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
};

export default Checkbox;