import React from "react";
import { Accordion, Card } from "react-bootstrap";
import { rand } from "@helper/helper";
import "@styles/components/accordion.scss";
import PropTypes from "prop-types";
import AccordionButton from "@components/AccordionButton";

const AccordionCard = ({
  cardId,
  open,
  toggleOpen,
  toggleClose,
  label,
  sub,
  children,
  icon,
  warningLabel,
  onCollapse,
}) => {
  const key = cardId ? cardId : rand();

  return (
    <Card className={"card-body"}>
      <Card.Header>
        <h4>
          {icon}
          {label}
          {warningLabel && (
            <span className="badge badge-danger badge-pill">
              {warningLabel}
            </span>
          )}
        </h4>
        {sub && <span className={"fs-5"}>{sub}</span>}
        <AccordionButton
          eventKey={key}
          collapse={open}
          onCollapse={onCollapse}
          toggleOpen={toggleOpen}
          toggleClose={toggleClose}
        />
      </Card.Header>
      <Accordion.Collapse eventKey={key} className={open ? "show" : ""}>
        <Card.Body>{children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

AccordionCard.propTypes = {
  label: PropTypes.string.isRequired,
};

export default AccordionCard;

