import React, {Fragment, useState} from 'react';
import {
    Button,
    Modal,
    Form,
    Alert,
    ListGroup,
    ListGroupItem,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import "@styles/components/modal.scss";
import PropTypes from "prop-types";
import CheckboxButton from "@components/form/Checkbox";
import {cancelBooking} from "@helper/api";
import Submit from "@components/form/Submit";
import {Trans, useTranslation} from "@language/Translator";
import {makeCancelItem} from "../../helper/cancellation";

const CancelBooking = ({
                           icon,
                           label,
                           title,
                           price,
                           agbLink,
                           cancelPolicy,
                           disabled,
                           bComReservationNotChangeableNotification
}) => {
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({reason: ''});

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const t = useTranslation();

    const onChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value});
    };

    const onCheck = (e) => {
        let data = {...form};

        if (e.target.checked) {
            data[e.target.name] = true;
        } else {
            delete data[e.target.name];
        }

        setForm(data);
    };

    const submit = async () => {
        if (await cancelBooking(form)) {
            setShow(false);
            setForm({});
        }
    };

    const getCancellation = (cancelPolicy) => {
        let cancelList = [];
        if (hasCancellations(cancelPolicy)) {
            for (const [index,cost] of Object.entries(cancelPolicy.cost)) {
                cancelList.push(makeCancelItem(
                    cancelPolicy.start,
                    cancelPolicy.end,
                    cancelPolicy.min,
                    cost,
                    index
                ));
            }
        }

        return cancelList.reverse();
    }

    const hasCancellations = (cancelPolicy) => {
        return cancelPolicy !== undefined && cancelPolicy !== null && cancelPolicy.cost !== undefined && Object.keys(cancelPolicy.cost).length > 0;
    }

    const ConditionalWrapper = ({condition, wrapper, children}) =>
        condition ? wrapper(children) : children;

    const renderTooltip = (props) =>
        <Tooltip id="button-tooltip" {...props}>
            {bComReservationNotChangeableNotification}
        </Tooltip>;

    return (
        <Fragment>
            <ConditionalWrapper
                condition={disabled}
                wrapper={children => (
                    <OverlayTrigger
                        placement="auto"
                        overlay={renderTooltip}
                    >
                        {children}
                    </OverlayTrigger>
                )}
            >
                <Button disabled={disabled} variant="outline"
                        onClick={handleShow} className={'modal__button'}>
                    {icon}
                    <div className={'modal__button__label'}>{label}</div>
                </Button>
            </ConditionalWrapper>

            <Modal show={show} onHide={handleClose} centered size={'lg'}>
                <Modal.Header closeButton>
                    <div className={'modal-title h5'}>{title}</div>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant={'secondary'}>
                        {price === -1 && (
                            <Fragment>
                                <Trans placeholder={[agbLink]}>modal_cancel_alert_info</Trans>
                            </Fragment>
                        )}
                        {price !== -1 && (
                            <Fragment>
                                <Trans placeholder={[<strong>{price.toFixed(2)}</strong>]}>modal_cancel_price</Trans>
                        </Fragment>)}
                    </Alert>
                    <hr/>
                    {hasCancellations(cancelPolicy) && (
                        <Form.Group>
                            <ListGroup>
                                <ListGroupItem active="1"
                                               className="blue-on"><Trans>list_cancel_policy_label</Trans></ListGroupItem>
                                {getCancellation(cancelPolicy)}
                            </ListGroup>
                        </Form.Group>
                    )}
                    {hasCancellations(cancelPolicy) && (
                        <hr/>
                    )}

                    <Form.Group className={form.reason ? '' : 'error-highlighting'}>
                        <Form.Label><Trans>modal_cancel_reason</Trans><span className={'required'}>*</span></Form.Label>
                        <Form.Control name={'reason'} as="select" onChange={onChange}>
                            <option value={''}>{t('cancel_reason_choose')}</option>
                            <option value={'not_suitable'}>{t('cancel_reason_not_suitable')}</option>
                            <option value={'already_occupied'}>{t('cancel_reason_already_occupied')}</option>
                            <option value={'holidays_canceled'}>{t('cancel_reason_holidays_canceled')}</option>
                            <option value={'personal_reasons'}>{t('cancel_reason_personal_reasons')}</option>
                            <option value={'other'}>{t('cancel_reason_other')}</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><Trans>modal_cancel_reason_message</Trans></Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder={t('modal_cancel_reason_message_placeholder')} name={'message'}
                                      value={form.message} onChange={onChange}/>
                    </Form.Group>
                    <CheckboxButton required label={t('modal_cancel_condition_information')} name={'agb'}
                                    value={1} onClick={onCheck}></CheckboxButton>
                    <div className={'required-info'}>
                        <span className={'required'}>*</span> <span><Trans>required_fields</Trans></span>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        <Trans>modal_close</Trans>
                    </Button>
                    <Submit variant="primary" onClick={submit}
                            disabled={form.agb && form.reason !== '' ? '' : 'disabled'}>
                        {price === -1 && (<Fragment><Trans>modal_cancel_by_agb</Trans></Fragment>)}
                        {price !== -1 && (<Fragment><Trans placeholder={[price.toFixed(2)]}>modal_cancel_by_price</Trans></Fragment>)}
                    </Submit>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

CancelBooking.propTypes = {
    price: PropTypes.number.isRequired,
};


export default CancelBooking;