import React, {Fragment} from "react";
import {Button as BootstrapButton} from "react-bootstrap";

const Button = ({children, to, right, disabled, className, ...args}) => {
    return (
        <Fragment>
            <BootstrapButton href={to} disabled={disabled ? 'disabled' : ''} className={`btn btn-primary ${right ? 'float-right': ''} ${className ? className : ''}`} {...args}>{children}</BootstrapButton>
        </Fragment>
    );
};

export default Button;