import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import Accordion from "@components/Accordion";
import AccordionCard from "@components/AccordionCard";
import Visitor from "@components/Visitor";
import {countValidTraveler} from "@helper/helper";
import {updateTraveller} from "@helper/api";
import Submit from "@components/form/Submit";
import {Trans, useTranslation} from "@language/Translator";

const TravelerAccordion = ({traveler, visitors, avsData}) => {
    const [collapse, setCollapse] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [canSubmit, setCanSubmit] = useState(false);

    const key = 'traveller-accordion';

    const t = useTranslation();

    const onCollapse = (state) => {
        setCollapse(state);
    };

    const submit = async () => {
        //we save only the additional customers and not the booker
        await updateTraveller({traveller: customers.slice(0)});
    };

    const change = async (data, visitorId, changed) => {
        let newData = {...customers};
        newData[visitorId] = data;
        setCustomers(Object.values(newData));

        let submitAble = {...canSubmit};
        submitAble[visitorId] = changed;

        //check is submit able if all data filled
        setCanSubmit(Object.values(newData).filter((item) => {
            return item.age
                && item.firstName
                && item.firstName.length > 0
                && item.lastName
                && item.lastName.length > 0
        }).length === Object.values(newData).length);
    };

    useEffect(() => {
        setCustomers(Object.values(traveler));
    }, [traveler]);

    return (
        <Fragment>
            <Accordion defaultActiveKey={collapse ? key : 0} collapse={collapse}>
                <AccordionCard
                    onCollapse={onCollapse}
                    cardId={key}
                    open={collapse}
                    icon={<i className="fa fa-user" />}
                    label={t('accordion_traveller_headline')}
                    warningLabel={visitors - countValidTraveler(customers) > 0 ? visitors - countValidTraveler(customers) : false}
                    sub={visitors - countValidTraveler(customers) > 0 ? t('accordion_traveller_missing_complete') : ''}>
                    <ul className={'list-group list-group-flush not-show list-group-shaded show-flex'}>
                        {customers.map((visitor, index) => (<Visitor key={index} visitor={visitor} index={index + 1} displayAge={!visitor.isAdult} readonly={index === 0} onChange={change} avsData={avsData}/>))}
                        {canSubmit && (
                            <li className={'list-group-item'}>
                                    <Submit right={true} onClick={submit}><Trans>btn_save</Trans></Submit>
                            </li>
                        )}
                    </ul>
                </AccordionCard>
            </Accordion>
        </Fragment>
    );
};

TravelerAccordion.propTypes = {
    traveler: PropTypes.array.isRequired,
    visitors: PropTypes.number.isRequired,
};

export default TravelerAccordion;