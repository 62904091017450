import {Col, Row, Tab} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Trans} from "@language/Translator";
import PropTypes from "prop-types";
import TabNav from "@components/TabNav";
import TabNavItem from "@components/TabNavItem";
import moment from "moment";
import Payment from "@components/payment/Payment";


const SplitPayment = ({asyncDsPayment, deposit, bookingNumber, final, daysThreshold}) => {
    const [tabKey, setTabKey] = useState('deposit');

    useEffect(() => {
        Math.ceil(deposit.deadline.diff(
            moment(),
            'days',
            true
        )) >= 0 ? setTabKey('deposit') : setTabKey('final');
    },[deposit,final]);

    return <Tab.Container
        activeKey={tabKey}
        onSelect={setTabKey}
        id={"payment-tab-container"}>
        <Row>
            <Col>
                <TabNav>
                    <TabNavItem
                        eventKey="deposit"><Trans>deposit_label</Trans></TabNavItem>
                    <TabNavItem
                        eventKey="final"><Trans>final_deposit_label</Trans></TabNavItem>
                </TabNav>
                <Tab.Content>
                    <Tab.Pane
                        eventKey="deposit">
                        <Payment
                            payment={deposit}
                            asyncDsPayment={asyncDsPayment}
                            bookingNumber={bookingNumber}
                            label={'deposit_label'}
                            daysThreshold={daysThreshold}
                        />
                    </Tab.Pane>
                    <Tab.Pane
                        eventKey="final">
                        <Payment
                            payment={final}
                            asyncDsPayment={asyncDsPayment}
                            bookingNumber={bookingNumber}
                            label={'final_deposit_label'}
                            daysThreshold={daysThreshold}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container>;
}

SplitPayment.propTypes = {
    asyncDsPayment: PropTypes.bool,
    deposit: PropTypes.any,
    bookingNumber: PropTypes.string,
    final: PropTypes.any,
    daysThreshold: PropTypes.number,
};

export default SplitPayment;