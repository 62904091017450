import React, {Fragment} from "react";
import {Nav} from "react-bootstrap";
import "@styles/components/tabNav.scss"

const TabNav = ({children}) => {
    return (
        <Fragment>
            <Nav variant="pills" className={'justify-content-center payment-tab'}>
                {children}
            </Nav>
        </Fragment>
    );
};

export default TabNav;