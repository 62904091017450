import Header from "@components/Header";
import {Col, Container, Row} from "react-bootstrap";
import moment from "moment";

import Navigation from "@sections/Navigation";

import React, {Fragment, useEffect, useState} from "react";

import "@styles/pages/dashboard.scss";
import ShortBooking from "@components/ShortBooking";
import PaymentAccordion from "@sections/accordion/PaymentAccordion";
import TravelerAccordion from "@sections/accordion/TravelerAccordion";
import ArrivalAccordion from "@sections/accordion/ArrivalAccordion";
import DepartureAccordion from "@sections/accordion/DepartureAccordion";
import Footer from "@sections/Footer";
import BookAdditionalServices from "@sections/BookAdditionalServices";
import ErgoTravelInsurance from "../sections/ErgoTravelInsurance";
import {useParams} from 'react-router-dom';
import {getBooking} from "@helper/api";
import {placeHolderBooking} from "@helper/placeholderBooking";
import Modal from "@components/Modal";
import PageContentLoader from "@components/PageContentLoader";
import { saveBookingAccess } from "@helper/helper";
import {Trans, useTranslation} from "@language/Translator";
import ScrollTopButton from "@components/ScrollTopButton";
import WebChat from "@components/WebChat";

const Dashboard = ({history}) => {
    const [booking, setBooking] = useState(false);
    const [notEditable, setNotEditable] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const {bookingNumber, token} = useParams();

    const t = useTranslation();

    const fetchBooking = async (bookingNumber, token) => {
        //save data in window
        saveBookingAccess(bookingNumber, token);

        const response = bookingNumber && token ? await getBooking() : false;
        setLoaded(true);

        if (response) {
            setBooking({...response.data});
            setNotEditable(false);
        }

        if(bookingNumber && token) {
            history.push(`/${bookingNumber}/${token}`);
        }
    };

    useEffect(() => {
        setBooking(placeHolderBooking());
        fetchBooking(bookingNumber, token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!booking) {
        return (<Fragment/>);
    }

    return (
        <Fragment>
            <PageContentLoader show={!loaded}/>
            { notEditable && (
                <Modal
                    show
                    closeAble={false}
                    title={t('modal_booking_not_available_headline')}>
                    <Trans placeholder={[`<strong>${window.CustomerData.bookingNumber ?? ''}</strong>`]}>modal_booking_not_available_message</Trans>
                </Modal>
            )}
            <Header
                image={booking.object.image}
                logo={booking.object.logo}
                title={booking.object.name}
                days={Math.ceil(booking.customer.arrival.date.diff(moment(), 'days', true))}
                className={notEditable ? 'not-readable' : ''}
            />
            <Container className={`navigation-container ${notEditable ? 'not-readable' : ''}`}>
                <Row>
                    <Col lg={{span: 10, offset: 1}} md={12}>
                        <Navigation booking={booking}/>
                    </Col>
                </Row>
            </Container>
            <Container className={notEditable ? 'not-readable' : ''}>
                <Row>
                    <Col lg={{span: 10, offset: 1}} md={12}>
                        <h4 className={'welcome'}><Trans placeholder={[booking.customer.salutation ? booking.customer.salutation : '', booking.customer.firstName, booking.customer.lastName]}>headline_salutation</Trans></h4>
                        <ul className={'list-group'}>
                            {booking.customer.bookingNumber && (
                                <ShortBooking
                                    bookingNumber={booking.customer.bookingNumber}
                                    adults={booking.customer.adults}
                                    children={booking.customer.children}
                                    childAges={booking.customer.traveler.filter((item) => { return !item.isAdult }).map(item => { return item.age })}
                                    object={booking.object.name}
                                    image={booking.object.room ? booking.object.room.image : ''}
                                    room={booking.object.room ? booking.object.room.name : ''}
                                    arrival={booking.customer.arrival}
                                    departure={booking.customer.departure}
                                />)}
                        </ul>
                        <h4 className={'spacer-top-3'}><Trans>headline_your_booking</Trans></h4>
                        <PaymentAccordion payment={booking.customer.payment}
                                          bookingNumber={booking.customer.bookingNumber} agb={booking.object.info.agb}/>
                        <TravelerAccordion traveler={booking.customer.traveler}
                                           visitors={booking.customer.adults + booking.customer.children}
                                           avsData={booking.avsData} />
                        <ArrivalAccordion time={booking.customer.arrival.time} arrival={booking.object.arrival}
                                          date={booking.customer.arrival.date} keyData={booking.object.info.key}
                                          address={{object: booking.object.address, customer: booking.customer.address}}/>
                        <DepartureAccordion date={booking.customer.departure.date}
                                            time={booking.customer.departure.time}/>

                        { booking.object.ergoLink && (
                            <h4 className={'spacer-top-3'}><Trans>headline_travel_insurance</Trans></h4>
                        )}
                        { booking.object.ergoLink && (
                            <ErgoTravelInsurance ergoLink={booking.object.ergoLink}/>
                        )}

                        {booking.object && booking.object.exclusives && booking.object.exclusives.length > 0 && (
                            <Fragment>
                                <h4 className={'spacer-top-3'}><Trans>headline_optionally_bookable</Trans></h4>
                                <BookAdditionalServices exclusives={booking.object.exclusives}/>
                            </Fragment>
                            )}
                    </Col>
                </Row>
            </Container>
            <Container className={`text-center ${notEditable ? 'not-readable' : ''}`}>
                <Row>
                    <Col>
                        <Footer/>
                    </Col>
                </Row>
            </Container>
            <WebChat
                reservationNumber={booking.customer.bookingNumber}
                zipCode={booking.customer.address ? booking.customer.address.zip : null}
                apiUrl={booking.chat.active ? booking.chat.apiUrl : null}
            />
            <ScrollTopButton withChat={booking.chat.active}/>
        </Fragment>
    );
};

export default Dashboard;