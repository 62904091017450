import React, {Fragment} from "react";
import {Form} from "react-bootstrap";
import { rand } from "@helper/helper";

import "@styles/components/form/checkboxButton.scss";

const CheckboxButton = ({children, value, name, className, ...args}) => {
    return (
        <Fragment>
            <Form.Check className={`inline check-box-container ${className ? className : ''}`} type={'checkbox'} id={`form-checkbox-${rand()}`}>
                <Form.Check.Input name={name} value={value} type={'checkbox'}{...args}/>
                <Form.Check.Label className={`btn btn-primary option-button`}>{children}</Form.Check.Label>
            </Form.Check>
        </Fragment>
    );
};

export default CheckboxButton;