import axios from "axios";
import React from "react";
import moment from "moment";
import {toast} from "react-toastify";
import config from "@config/config";
import {Trans} from "@language/Translator";

if(window.location.hostname !== 'localhost' && config && config.api && config.api.host && config.api.host.length > 0) {
    axios.defaults.baseURL = `${config.api.host}/`;
}

export const sendMailToLandlord = async (data) => {
    try {
        const response = await axios.post(`/${getRoute()}/landlord/mail`, data, {...buildHeader()});
        toast.success(<Trans>notify_contact_landlord_success</Trans>);

        if(typeof response.data.success === 'string') {
            //execute if server get a sandbox response
            const CustomToastWithLink = () => (
                    <a target={'_blank'} href={response.data.success} style={{color: '#fff'}} rel="noreferrer">Sandbox: view eMail. Click here.</a>
            );

            toast.info(CustomToastWithLink, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }

        return true;
    } catch(err) {
        error(err);
        return false;
    }
};

export const updateArrival = async (data) => {
    try {
        const response = await axios.put(`/${getRoute()}/arrival`, data, {...buildHeader()});

        if(response.data.success) {
            toast.success(<Trans>notify_arrival_change_success</Trans>);
        } else {
            toast.error(<Trans>notify_arrival_change_error</Trans>);
        }

        return true;
    } catch(err) {
        error(err);
        return false;
    }
};

export const bookAdditionalServices = async (data) => {
    try {
        await axios.put(`/${getRoute()}/exclusive`, {products: data}, {...buildHeader()});
        toast.success(<Trans>notify_book_addition_service_success</Trans>);
        return true;
    } catch(err) {
        error(err);
        return false;
    }
};

export const cancelBooking = async (data) => {
    try {
        await axios.put(`/${getRoute()}/cancel`, data, {...buildHeader()});
        toast.success(<Trans>notify_booking_cancel_success</Trans>);
        return true;
    } catch(err) {
        error(err);
        return false;
    }
};

export const updateTraveller = async (data) => {
    try {
        await axios.put(`/${getRoute()}/traveler`, data, {...buildHeader()});
        toast.success(<Trans>notify_update_traveller_success</Trans>);
        return true;
    } catch(err) {
        error(err);
        return false;
    }
};

export const getBooking = async () => {
    try {
        const response = await axios.get(`/${getRoute()}`, {...buildHeader()});

        let data = {...response};

        data.data.customer.arrival.date = moment(data.data.customer.arrival.date);
        data.data.customer.departure.date = moment(data.data.customer.departure.date);
        data.data.customer.payment.deposit.deadline = moment(data.data.customer.payment.deposit.deadline);
        data.data.customer.payment.final.deadline = moment(data.data.customer.payment.final.deadline);

        return data;
    } catch(err) {
        const data = getCustomErrorHandling(err);

        if(data) {

        } else {
            error(err);
        }

        return false;
    }
};

const getBookingNumber = () => {
    return window.CustomerData.bookingNumber;
};

const getRoute = () => {
    return `api/booking/${getBookingNumber()}`;
};

const buildHeader = () => {
    return {
        headers: {
            'x-auth-token': window.CustomerData.token
        }
    }
};

const error = (err) => {
    toast.error(<Trans>notify_error</Trans>);
    console.log(err);
};

/**
 * Catch custom errors to avoid error notification.
 *
 * @param err
 * @returns {boolean|*}
 */
const getCustomErrorHandling = (err) => {
    if(err.response && err.response.data && err.response.data.errors) {
        for(let ii = 0; ii < err.response.data.errors.length; ii++) {

            if(typeof err.response.data.errors[ii].msg === 'object') {
                return err.response.data.errors[ii];
            }

            if (typeof err.response.data.errors[ii].msg === 'string' &&
                err.response.data.errors[ii].msg.startsWith('bookingNumber not exist')
            ) {
                return err.response.data.errors[ii];
            }
        }
    }

    return false;
};