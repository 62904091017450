import React, {Fragment} from "react";
import {Nav} from "react-bootstrap";
import "@styles/components/tabNav.scss"

const TabNavItem = ({children, eventKey}) => {
    return (
        <Fragment>
            {eventKey && (
                <Nav.Item>
                    <Nav.Link className={'tab-nav-bar-item btn btn-outline-primary'}
                              eventKey={eventKey}>{children}</Nav.Link>
                </Nav.Item>
            )}
        </Fragment>
    );
};

export default TabNavItem;