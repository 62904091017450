import React, {Fragment, useEffect, useState} from "react";
import {Col, Container, Row, Form} from "react-bootstrap";
import { camelize } from "@helper/helper";

import "@styles/components/visitor.scss";
import PropTypes from "prop-types";
import Submit from "@components/form/Submit";
import {Trans, useTranslation} from "@language/Translator";

const Visitor = ({index, visitor, displayAge, readonly, onChange, onSubmit, avsData}) => {
    const [initial, setInitial] = useState({lastName: '', firstName: '', age: 0, car: '', email: '',
        birthday: '', categoryId: '', countryId: ''});
    const [formData, setFormData] = useState({lastName: '', firstName: '', age: 0, car: '', email: '',
        birthday: '', categoryId: '', countryId: ''});
    const [canSubmit, setCanSubmit] = useState(false);

    const t = useTranslation();

    useEffect(() => {
        setInitial(visitor);
        setFormData(visitor);
    }, [visitor]);

    const change = (e) => {
        const name = camelize(e.target.name);
        const type = e.target.getAttribute('type');
        const newData = {...formData, [name]: type === 'number' ? parseInt(e.target.value) :  e.target.value};
        const changed = initial.age !== newData.age ||
            (newData.firstName && newData.firstName.trim().length > 0 &&
                initial.firstName !== newData.lastName) ||
            (newData.lastName && newData.lastName.trim().length > 0 &&
                initial.lastName !== newData.lastName);

        setFormData(newData);
        if(onSubmit) { setCanSubmit(changed); }

        if(onChange) {
            onChange(newData, parseInt(index) - 1, changed);
        }
    };

    const submit = async () => {
        if(onSubmit) {
            if(await onSubmit(formData, parseInt(index) - 1)) {
                setInitial({...formData});
                setCanSubmit(false);
            }
        }

        return true;
    };

    return (
        <Fragment>
            <li className={'list-group-item'}>
                <span className={'font-weight-bold'}><Trans placeholder={[index]}>visitor_person</Trans>
                    &nbsp;<small>
                        {formData.age < 18 ? t('child') : t('adult')}
                        {formData.age < 18 && (<Trans placeholder={[formData.age]}>visitor_child_age</Trans>)}
                    </small>
                    {formData.firstName.length === 0 && formData.lastName.length === 0 && (<span className="badge badge-secondary"><Trans>visitor_full_name_missing</Trans></span>)}
                </span>
                <Container className={'visitor-data-set'}>
                    <Row>
                        {displayAge && (
                            <Col md={2} xs={12}>
                                <Form.Group controlId={`visitor-${index}-age`}>
                                    <Form.Label>Alter</Form.Label>
                                    <Form.Control min={0} type="number" name={'age'} value={formData.age} onChange={change} />
                                </Form.Group>
                            </Col>
                        )}
                        <Col md={displayAge ? 5 : 6} xs={12}>
                            <Form.Group controlId={`visitor-${index}-first-name`}>
                                <Form.Label><Trans>label_first_name</Trans></Form.Label>
                                <Form.Control type="text" name={'first-name'} value={formData.firstName} readOnly={readonly ? 'disabled' : ''} disabled={readonly ? 'disabled' : ''} placeholder={t('label_first_name')} onChange={change}/>
                            </Form.Group>
                        </Col>
                        <Col md={displayAge ? 5 : 6} xs={12}>
                            <Form.Group controlId={`visitor-${index}-last-name`}>
                                <Form.Label><Trans>label_last_name</Trans></Form.Label>
                                <Form.Control type="text" name={'last-name'} value={formData.lastName} readOnly={readonly ? 'disabled' : ''} disabled={readonly ? 'disabled' : ''} placeholder={t('label_last_name')} onChange={change}/>
                            </Form.Group>
                        </Col>
                    </Row>
                        <Row>
                            <Col md={!readonly ? 12 : 6} xs={12}>
                                <Form.Group controlId={`visitor-${index}-email`}>
                                    <Form.Label>{t(index > 0 ? 'label_email_personal': 'label_email')}</Form.Label>
                                    <Form.Control type={'email'} name={'email'} value={formData.email} placeholder={t(index > 0  ? 'label_email_personal': 'label_email')} onChange={change}/>
                                </Form.Group>
                            </Col>
                            {readonly && (
                            <Col md={6} xs={12}>
                                <Form.Group controlId={`visitor-${index}-car`}>
                                    <Form.Label><Trans>label_car</Trans></Form.Label>
                                    <Form.Control type="text" name={'car'} value={formData.car} placeholder={t('label_car')} onChange={change}/>
                                </Form.Group>
                            </Col>)}
                        </Row>
                    {avsData && (<Row>
                        <Col md={index === 1 ? 4 : 6} xs={12}>
                            <Form.Group controlId={`visitor-${index}-birthday`}>
                                <Form.Label><Trans>label_birthday</Trans></Form.Label>
                                <Form.Control type={'date'} name={'birthday'} value={formData.birthday} placeholder={t('label_birthday')} onChange={change}/>
                            </Form.Group>
                        </Col>
                        <Col md={index === 1 ? 4 : 6} xs={12}>
                            <Form.Group controlId={`visitor-${index}-categoryId`}>
                                <Form.Label><Trans>label_category</Trans></Form.Label>
                                <Form.Control as="select" name={'categoryId'} value={formData.categoryId} placeholder={t('label_category')} onChange={change}>
                                    <option value="0"></option>
                                    {avsData.categories.map((avsCategory,key) => <option value={avsCategory.id}>{avsCategory.name}</option> )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        {index === 1 && (<Col md={4} xs={12}>
                            <Form.Group controlId={`visitor-${index}-countryId`}>
                                <Form.Label><Trans>label_country</Trans></Form.Label>
                                <Form.Control as="select" name={'countryId'} value={formData.countryId} placeholder={t('label_country')} onChange={change}>
                                    <option value="0"></option>
                                    {avsData.countries.map((avsCountry,key) => <option value={avsCountry.id}>{avsCountry.name}</option> )}
                                </Form.Control>
                            </Form.Group>
                        </Col>)}
                    </Row>)}
                    {canSubmit && (
                    <Row>
                        <Col md={12}>
                            <Submit right={true} onClick={submit}><Trans>btn_save</Trans></Submit>
                        </Col>
                    </Row>
                    )}
                </Container>
            </li>
        </Fragment>
    );
};

Visitor.propTypes = {
    visitor: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
};

export default Visitor;