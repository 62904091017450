import React, {Fragment, useState} from 'react';
import {Button, Modal as BsModal} from "react-bootstrap";

import "@styles/components/modal.scss";
import {Trans} from "@language/Translator";

const Modal = ({button, icon, label, title, children, closeAble, ...args}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <Fragment>
            {label && (
                <Button variant="outline" onClick={handleShow} className={button ? 'btn btn-primary' : 'modal__button'}>
                    {icon}
                    {!button && (<div className={'modal__button__label'}>{label}</div>)}
                    {button && label}
                </Button>
            )}

            <BsModal show={show} onHide={handleClose} centered {...args}>
                <BsModal.Header closeButton={closeAble !== false}>
                    <div className={'modal-title h5'}>{title}</div>
                </BsModal.Header>
                <BsModal.Body>{children}</BsModal.Body>
                {closeAble !== false && (
                <BsModal.Footer>
                    <Button variant="outline-primary" onClick={handleClose}>
                        <Trans>modal_close</Trans>
                    </Button>
                </BsModal.Footer>)}
            </BsModal>
        </Fragment>
    );
};

export default Modal;