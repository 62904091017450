import React, {Fragment} from "react";
import moment from "moment";
import Link from "@components/Link";
import {Trans} from "@language/Translator";

const Footer = ({date, time}) => {
    return (
        <Fragment>
            <p>&copy; {moment().format('YYYY')} - <Trans>footer_copy_right</Trans></p>
            <div>
                <Link url={'https://www.ds-destinationsolutions.com/impressum.html'}><Trans>footer_imprint</Trans></Link>
                -
                <Link url={'https://www.ds-destinationsolutions.com/datenschutz-sicherheit.html'}><Trans>footer_data_protection</Trans></Link>
            </div>
        </Fragment>
    );
};


export default Footer;