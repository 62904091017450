import React, {Fragment, useState} from "react";
import {useAccordionToggle} from "react-bootstrap";
import '@styles/components/accordion.scss';

const AccordionButton = ({eventKey, toggleOpen, toggleClose, collapse, onCollapse}) => {

    toggleOpen = toggleOpen ? toggleOpen : '+';
    toggleClose = toggleClose ? toggleClose : '-';

    const [open, setOpen] = useState(collapse ? collapse : false);

    const decoratedOnClick = useAccordionToggle(eventKey, (e) => {
        if(onCollapse) {
            setOpen(true);
            onCollapse(!open);
        }

        setOpen(!open);
    });

    return (
        <Fragment>
            <button
                className={'accordion__card__toggle btn btn-primary float-right btn-show-more'}
                type="button"
                onClick={decoratedOnClick}
            >
                {open ? toggleClose : toggleOpen}
            </button>
        </Fragment>
    );
};

export default AccordionButton;