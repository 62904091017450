import React, {useState} from 'react';
import {AiFillCaretUp} from 'react-icons/ai';
import Button from '@components/Button';
import "@styles/components/scrollTopButton.scss";
import {useTranslation} from "@language/Translator";


const ScrollTopButton = props =>{

    const [visible, setVisible] = useState(false);

    const t = useTranslation();

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 150){
            setVisible(true)
        }
        else if (scrolled <= 150){
            setVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <Button title={t('scroll_top_description')} className={`back-to-top ${props.withChat ? 'back-to-top-chat' : ''} ${visible ? 'back-to-top-visible' : ''}`} onClick={scrollToTop}>
            <AiFillCaretUp/>
        </Button>
    );
};

export default ScrollTopButton;