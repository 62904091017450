import React from 'react';

import DefaultLogo from '@images/ds-logo-22.png';

import '@styles/components/header.scss';
import {Trans} from "@language/Translator";

const Header = ({image, logo, title, days, className}) => {
    const headerStyles = {};
    const logoStyles = {backgroundImage:  `url("${logo ? logo : DefaultLogo}")`};

    if(image) {
        headerStyles.backgroundImage = `url("${image}")`;
    }

    return (
        <header style={headerStyles} className={className}>
            <div className={'header__logo-container'}>
                <div className={'header__logo-container__logo'} style={logoStyles} />
            </div>
            <h2 className={'header__h2'}>
                <span className={'header__light-box'}><Trans placeholder={[title]}>header_title</Trans></span>
            </h2>
            { days > 0 && (
                <h3 className={'header__h3'}>
                    <span><Trans placeholder={days.toString()}>header_holiday_counter</Trans></span>
                </h3>)
            }
        </header>
    );
};

export default Header;