import {stringFormat} from "@helper/helper";
import parse from 'html-react-parser'
import i18n from "i18next";

const translator = (text, placeholder) => {
    return stringFormat(i18n.t(text), placeholder);
};

const Trans = ({placeholder, children}) => {
    return parse(translator(children, placeholder));
};

/**
 * we call translator in react components in such a way that it suspects how the default react translator is used.
 * so that a later refactor causes as little effort as possible.
 *
 * @returns {function(*, *=): *}
 */
const useTranslation = () => {
    return translator;
};


export {Trans, useTranslation};