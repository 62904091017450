import React, { Fragment, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Accordion from "@components/Accordion";
import AccordionCard from "@components/AccordionCard";
import moment from "moment";
import PropTypes from "prop-types";
import Modal from "@components/Modal";
import { Trans, useTranslation } from "@language/Translator";
import PrePaidDS from "@components/payment/PrePaidDS";
import SplitPayment from "@components/payment/SplitPayment";
import Payment from "@components/payment/Payment";

const PaymentAccordion = ({ payment, bookingNumber, agb }) => {
  const [collapse, setCollapse] = useState(false);

  const key = "payment-accordion";

  const daysThreshold = 14;

  const t = useTranslation();

  const onCollapse = (state) => {
    setCollapse(state);
  };

  const getNextDeadline = (deposit, final) => {
    if (
      deposit.price &&
      Math.ceil(deposit.deadline.diff(moment(), "days", true)) >= 0
    ) {
      return deposit.deadline;
    }

    return final.deadline;
  };

  const deadlineIndex = (deadline) => {
    if (
      Math.ceil(deadline.diff(moment(), "days", true)) <= 0 ||
      Math.ceil(deadline.diff(moment(), "days", true)) > daysThreshold
    ) {
      return "accordion_payment_deadline_date";
    }
    return "accordion_payment_deadline_days";
  };
  const deadlineValue = (deadline) => {
    if (
      Math.ceil(deadline.diff(moment(), "days", true)) <= 0 ||
      Math.ceil(deadline.diff(moment(), "days", true)) > daysThreshold
    ) {
      return deadline.format(t("date_format"));
    }

    return Math.ceil(deadline.diff(moment(), "days", true));
  };

  const exceededDeadline = (nextDeadline) => {
    return Math.ceil(nextDeadline.diff(moment(), "days", true)) < 0;
  };

  return (
    <Fragment>
      <Accordion defaultActiveKey={collapse ? key : 0} collapse={collapse}>
        <AccordionCard
          onCollapse={onCollapse}
          cardId={key}
          open={collapse}
          icon={<i className="fa fa-euro" />}
          label={t("accordion_payment_headline")}
          sub={
            !exceededDeadline(getNextDeadline(payment.deposit, payment.final))
              ? t(
                  deadlineIndex(
                    getNextDeadline(payment.deposit, payment.final)
                  ),
                  [
                    deadlineValue(
                      getNextDeadline(payment.deposit, payment.final)
                    ),
                  ]
                )
              : ""
          }
        >
          <ul
            className={
              "list-group list-group-flush not-show list-group-shaded show-flex"
            }
          >
            <li className={"list-group-item"}>
              <Container>
                <Row>
                  <Col>
                    <span className={"font-weight-bold"}>
                      <Trans
                        placeholder={[
                          payment.price ? payment.price.toFixed(2) : 0,
                        ]}
                      >
                        accordion_payment_end_price
                      </Trans>
                    </span>
                    {payment.tax.length > 0 && (
                      <Trans
                        placeholder={[
                          payment.tax.map((item) => {
                            return t("accordion_payment_tax", [
                              item.value.toFixed(2),
                              parseFloat(item.percent).toFixed(2),
                            ]);
                          }),
                        ]}
                      >
                        accordion_payment_tax_list
                      </Trans>
                    )}
                    {agb && (
                      <p>
                        <Modal
                          button
                          label={t("accordion_payment_agb_label")}
                          title={t("accordion_payment_agb_title")}
                        >
                          <div
                            className="modal-body"
                            dangerouslySetInnerHTML={{ __html: agb }}
                          />
                        </Modal>
                      </p>
                    )}
                  </Col>
                </Row>
              </Container>
            </li>
            <li className={"list-group-item"}>
              {(payment.typeOfPaymentDS === "sync" && <PrePaidDS />) ||
                (payment.deposit.price !== 0.0 &&
                  payment.final.price !== 0.0 && (
                    <SplitPayment
                      asyncDsPayment={payment.typeOfPaymentDS === "async"}
                      deposit={payment.deposit}
                      bookingNumber={bookingNumber}
                      final={payment.final}
                    />
                  )) ||
                (payment.deposit.price !== 0.0 && (
                  <Payment
                    payment={payment.deposit}
                    label={"single_payment_label"}
                    bookingNumber={bookingNumber}
                    asyncDsPayment={payment.typeOfPaymentDS === "async"}
                  />
                )) ||
                (payment.final.price !== 0.0 && (
                  <Payment
                    payment={payment.final}
                    label={"single_payment_label"}
                    bookingNumber={bookingNumber}
                    asyncDsPayment={payment.typeOfPaymentDS === "async"}
                  />
                ))}
            </li>
          </ul>
        </AccordionCard>
      </Accordion>
    </Fragment>
  );
};

PaymentAccordion.propTypes = {
  payment: PropTypes.object.isRequired,
  bookingNumber: PropTypes.string.isRequired,
};

export default PaymentAccordion;

