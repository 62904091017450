import React from "react";
import {Col, Row} from "react-bootstrap";
import Modal from "@components/Modal";
import ContactLandlord from "@sections/modals/ContactLandlord";
import CancelBooking from "@sections/modals/CancelBooking";

import "@styles/sections/navigation.scss";
import Frame from "@components/Frame";
import {useTranslation} from "@language/Translator";

const Navigation = ({booking}) => {
    /**
     * Vars
     */
    const t = useTranslation();
    const navigationItemWidth = booking.object.contact.available && (booking.object.contact.available.email || booking.object.contact.available.phone) ? 3 : 4;

    return (
        <div className={'navigation card card-navig shadow'}>
            <div className={'card-body'}>
                <Row>
                    {booking.object.contact.available && (booking.object.contact.available.email || booking.object.contact.available.phone) && (
                    <Col lg={navigationItemWidth} sm={6} md={navigationItemWidth}>
                        <ContactLandlord
                            contact={booking.object.contact}
                            icon={<i className={'fa fa-send'} />}
                            label={t('navigation_contact_landlord_label')}
                            title={t('navigation_contact_landlord_title')}
                            />
                    </Col>)}
                    <Col lg={navigationItemWidth} sm={6} md={navigationItemWidth}>
                        <Modal
                            icon={<i className={'fa fa-list-alt'} />}
                            label={t('navigation_booking_confirmation_label')}
                            title={t('navigation_booking_confirmation_title')}
                            size={'lg'}
                        >
                            <Frame src={booking.customer.bookingConfirmation} title={t('navigation_booking_confirmation_title')} />
                        </Modal>
                    </Col>
                    <Col lg={navigationItemWidth} sm={6} md={navigationItemWidth}>
                        <Modal
                            icon={<i className={'fa fa-photo'} />}
                            label={t('navigation_object_view_label')}
                            title={t('navigation_object_view_title')}
                            size={'lg'}
                        >
                            <Frame src={`https://www.buchen.travel/onepage-booking/?noBooking=1&obj=${booking.object.id}`} title={t('navigation_object_view_title')} />
                        </Modal>
                    </Col>
                    <Col lg={navigationItemWidth} sm={6} md={navigationItemWidth}>
                        <CancelBooking
                            price={booking.object.cancel.price}
                            agbLink={booking.object.info.agbLink}
                            cancelPolicy={booking.object.cancellationPolicy}
                            icon={<i className={'fa fa fa-cog'} />}
                            label={t('navigation_change_booking_label')}
                            title={t('navigation_change_booking_title')}
                            disabled={booking.isBookingComReservation}
                            bComReservationNotChangeableNotification={t('navigation_change_booking_b_com_reservation_not_changeable_notification')}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Navigation;