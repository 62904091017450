/*eslint-disable no-eval */
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";

export const rand = (min, max) => {

    min = min ? min : 0;
    max = max ? max : 9999999;

    return Math.floor(Math.random() * (max - min) ) + min;
};

export const saveBookingAccess = (bookingNumber, token) => {
    window.CustomerData = {
        bookingNumber: bookingNumber,
        token: token
    };
};

export const camelize = (str) => {
    str = str.replace(/[-]+/, ' ');
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
};

export const stringFormat = (string, data) => {
    if(data) {
        const oldStr = string;
        data = data instanceof Array ? data : [data];
        const match = string.match(/%[^\s]/g);

        if(match) {
            for(let ii = 0; ii < match.length; ii++) {
                if(ii < data.length) {
                    string = string.replace(match[ii], data[ii]);
                }
            }
        }

        const ifMatch = string.match(/{.*?}/g);

        if(ifMatch) {
            for(let ii = 0; ii < ifMatch.length; ii++) {
                const ifItem = ifMatch[ii].match(/{(.*)?\?\s*([^:}]*)\s*(:\s*([^}\s]*)\s*)?}/);

                let compare = '';
                const conditions = ifItem[1].match(/(p)?\[?([^\s\]&|]+)]?\s*([<>=!]*)\s*(p)?\[?([^?\s\]&|]*)]?/g);
                const conditionConnections = ifItem[1].match(/&&|\|\|/g);

                for(let jj = 0; jj <  conditions.length; jj++) {
                    const condition = conditions[jj].match(/(p)?\[?([^\s\]&|]+)]?\s*([<>=!]*)\s*(p)?\[?([^?\s\]&|]*)]?/);
                    const comV1 = condition[1] === 'p' &&
                        data.hasOwnProperty(String([condition[2]])) ?
                            data[condition[2]] : condition[2];
                    const comp = condition[3];
                    const comV2 = condition[4] === 'p' &&
                        data.hasOwnProperty(String([condition[5]])) ?
                            data[condition[5]] : condition[5];
                    const connector = conditionConnections && jj > 0 ?
                        conditionConnections[jj - 1] : '';
                    compare += `${connector}${comV1} ${comp} ${comV2}`.trim();
                }

                const rTrue = ifItem[2] ? ifItem[2] : '';
                const rFalse = ifItem[4] ? ifItem[4] : '';
                try {
                    const result = eval(compare.trim());

                    string = string.replace(ifItem[0], result ? rTrue : rFalse);
                } catch(e) {
                    toast.error(`can't execute condition "${ifItem[0]}" in translation "${oldStr}".`, {
                        position: "top-right",
                        autoClose: false,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }
            }
        }
    }

    return string;
};

export const sum = (arr) => {
    let sum = 0;

    for (let number of arr) {
        if(isNaN(number)) {
            continue;
        }
        sum += parseFloat(number);
    }

    return sum;
};

export const countValidTraveler = (arr) => {
    let sum = 0;

    for (let index in arr) {
        let traveler = arr[index];
        if(traveler.age > 0 && traveler.firstName && traveler.firstName.length > 0 && traveler.lastName && traveler.lastName.length > 0) {
            sum++;
        }
    }

    return sum;
};

export const QueryVar = (field) => {
    const search = useLocation().search;
    return new URLSearchParams(search).get(field);
};

export const toHttpQueryParams = (obj) => {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
};