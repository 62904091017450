import moment from "moment";

export const placeHolderBooking = () => {
    return {
        chat: {
            active: false
        },
        customer: {
            salutation: 'Herr',
            firstName: 'Max',
            lastName: 'Mustermann',
            arrival: {
                date: moment('2022-01-01'),
                time: 18
            },
            departure: {
                date: moment('2022-01-15'),
                time: 10
            },
            adults: 5,
            children: 2,
            bookingNumber: '821511504d9',
            traveler: [
                {age: 0, firstName: 'Max', lastName: 'Mustermann'},
                {age: 9, firstName: '', lastName: ''},
                {age: 32, firstName: '', lastName: '', isAdult: true},
            ],
            bookingConfirmation: '',
            payment: {
                bank: {
                    name: 'Pommersche Volksbank e.G.',
                    receiver: 'Villa Am Holzbach GbR',
                    iban: 'DE54 1309 1054 5874 9899 83',
                    bic: 'GENODEF1HH'
                },
                price: 545.00,
                deposit: {
                    price: 463.25,
                    deadline: moment('2021-08-01')
                },
                final: {
                    price: 70.25,
                    deadline: moment('2021-10-01')
                },
                tax: {
                    mwst: []
                }
            }
        },
        object: {
            id: 0,
            image: 'https://images.im-web.de/objekt_pics/obj_full_51959_003.jpg?no-cache=1613997663',
            logo: false,
            name: 'Villa am Meer',
            room: {
                id: 0,
                name: 'Wohneinheit: EG1',
                image: 'https://images.im-web.de/zimmer_pics/zim_full_159339_001.jpg?no-cache=1613997283'
            },
            contact: {
                phone: '135464687'
            },
            cancel: {
                price: 987
            },
            arrival: {
                '19': '19:00',
                '20': '20:00',
                '21': '21:00',
                '22': '22:00',
                '23': '23:00',
            },
            info: {
                key: 'Die Schlüssel erhalten Sie bei uns in der Agentur in der Hauptstr 16 in Sassnitz bis 20:00 Uhr.\n' +
                    'Anreise nach 20:00 bitte unter Tel. 0175 7877 444 melden.',
                agb: '',
            },
            exclusives: [
                {label: 'Bettwäsche', price: 15, booked: false, name: 'bed'},
                {label: 'Handtücherpaketbig', price: 22, booked: false, name: 'towl'},
                {label: 'Brötchen', price: 0.25, booked: false, name: 'pety'},
                {label: 'Reinigung', price: 60, booked: false, name: 'clean'},
                {label: 'WLAN', price: 80, booked: false, name: 'lan'},
                {label: 'Parkplatz', price: 70, booked: true, name: 'park'}
            ]
        }
    }
};